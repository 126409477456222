<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title slot="start"> Introduceți localitatea / drumul </ion-title>
    <ion-buttons slot="end">
      <button (click)="closeModal()" class="button icon clear">
        <mat-icon>close</mat-icon>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="road-search">
    <ion-searchbar #searchBar [(ngModel)]="searchTerm" (keyup)="setFilteredItems(searchTerm)"> </ion-searchbar>
    <div class="items-list" *ngIf="items.length">
      <div button class="item" (click)="localitateClick(item)" *ngFor="let item of items">
        <div class="icon">
          <mat-icon>place</mat-icon>
        </div>

        <div class="text">
          <span class="title">{{ item.properties.class | titlecase }}</span>
          <span class="desc"> {{ item.properties.name }}</span>
        </div>
      </div>
    </div>
  </div>
</ion-content>
