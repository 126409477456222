<div>
<ion-header>
	<ion-toolbar>
	  <ion-title>Legenda</ion-title>
	</ion-toolbar>
  </ion-header>
</div>  


<ion-content>

<div>
		<ion-list>
				<ion-item>
					<!--ion-icon name="cellular-outline"></ion-icon-->
					<!--ion-icon name="radio-button-on" style="color: #339966"></ion-icon-->
					<ion-icon name="radio-outline"  style="color: #339966"></ion-icon>
					<ion-label >Semnal Foarte Bun</ion-label>
				</ion-item>
				<ion-item>
					<ion-icon name="radio-outline" style="color: #ff9900"></ion-icon>
					<ion-label >Semnal Mediu</ion-label>
				</ion-item>
				<ion-item>
					<ion-icon name="radio-outline" style="color: #999999"></ion-icon>
					<ion-label >Semnal Slab</ion-label>
				</ion-item>
				<ion-item>
					<ion-icon name="radio-outline" style="color: #ff0000"></ion-icon>
					<ion-label >Semnal Insuficient</ion-label>
				</ion-item>
				<!--ion-item>
					<ion-icon name="radio-button-on" style="color: #934815"></ion-icon>
					<ion-label >Fără Semnal</ion-label>
				</ion-item-->
			</ion-list>
</div>
</ion-content>