export interface RegionDetails extends IUnitDetails {
  regiune: string;
}

export interface LocalityDetails extends IUnitDetails {
  id_localitate: string;
  id_raion: string;
  an: string;
  raion: string;
  localitate: string;
}

export interface IUnitDetails {
  valori: {
    abonati: {
      Total: string | number;
      xDSL: string | number;
      FTTx: string | number;
      Cablu: string | number;
      Altele: string | number;
    };
    ponderea: {
      xDSL: string | number;
      FTTx: string | number;
      Cablu: string | number;
      Altele: string | number;
    };
  };
}

export const legendChartDefault = [
  {
    name: 'FTTx',
    value: 0
  },
  {
    name: 'xDSL',
    value: 0
  },
  {
    name: 'Cablu',
    value: 0
  },
  {
    name: 'Altele',
    value: 0
  }
];
