import { NgModule } from "@angular/core";
import { MatOptionModule, MatRippleModule } from '@angular/material/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';


@NgModule({
  exports: [
    MatButtonModule,
    MatIconModule,
    MatOptionModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatRippleModule,
    MatRadioModule

  ]
})
export class MaterialModule { }