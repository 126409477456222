import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ModalController, IonSegment } from '@ionic/angular';
import { SearchPage } from '../modal/search/search.page';
import * as mapboxgl from 'mapbox-gl';

import { environment } from '../../environments/environment';
import { KpiinfoPage } from '../modal/kpiinfo/kpiinfo.page';
import { VideoPage } from '../modal/video/video.page';
import { Storage } from '@ionic/storage';
import { MapLayersService } from 'src/app/services/map-layers.service';

import layers from '../../assets/layersNew.json';
import { UtilService } from '../services/utils.service';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss']
})
export class HomePage implements OnDestroy {
  @ViewChild('operatorsSegment', { static: true }) operatorsSegment: IonSegment;
  @ViewChild('voiceOrDataSegment', { static: true }) voiceOrDataSegment: IonSegment;
  @ViewChild('map', { static: true }) map: mapboxgl.Map;

  private layoutState = [];
  private yearState = [];

  public min_year = layers['years'].min_year;
  public max_year = layers['years'].max_year;
  public selected_year = Number(layers['years'].selected_year);

  prevOperator = '';

  showVoiceButton = false;
  showDataButton = false;
  show2G = false;
  showVideo = false;

  constructor(
    private modalController: ModalController,
    private storage: Storage,
    private mapLayersService: MapLayersService,
    private utilService: UtilService
  ) {
    this.utilService.insertVisit('home').subscribe(() => {});
    this.yearsButtonClicked();
    this.changeLayoutStatus();
  }

  async getShowVideo(): Promise<void> {
    return await new Promise(() => {
      this.storage.get('VIDEO').then(response => {
        if (response) {
          this.showVideo = response.showVideo;
        } else {
          this.showVideo = true;
        }
      });
    });
  }

  async showVideoFun() {
    if (this.showVideo) {
      this.showVideoPage();
    }
  }

  async voiceOrDataChanged() {
    this.analytics('voiceOrDataChanged');

    if (this.showVoiceButton == false && this.showDataButton == false) {
      return;
    }

    if (['voice', 'roads'].includes(this.voiceOrDataSegment.value.toString())) {
      this.showVoiceButton = true;
      this.showDataButton = false;
    } else {
      this.showVoiceButton = false;
      this.showDataButton = true;
    }

    Object.keys(this.layoutState).forEach(key => (this.layoutState[key] = false));
    await this.changeLayoutStatus();
  }

  async operatorChanged() {
    this.analytics('operatorChanged');

    if (['voice', 'roads'].includes(this.voiceOrDataSegment.value.toString())) {
      this.showVoiceButton = true;
      this.showDataButton = false;
    } else {
      this.showVoiceButton = false;
      this.showDataButton = true;
    }
    this.show2G = this.operatorsSegment.value !== 'unite';
    Object.keys(this.layoutState).forEach(key => {
      if (key.substr(0, 1) == this.prevOperator.substr(0, 1)) {
        if (this.layoutState[key] == true) {
          this.layoutState[key] = false;
          const t = this.operatorsSegment.value.toString().substr(0, 1) + key.substr(1, 1);
          this.layoutState[t] = true;
        }
      }
    });

    await this.changeLayoutStatus();
    this.prevOperator = this.operatorsSegment.value.toString();
  }

  getButtonColor(ev: string) {
    const layoutStateItem = Object.keys(this.layoutState).find(item => item.substr(1, 1) === ev.substr(0, 1));
    return this.layoutState[layoutStateItem] ? 'primary' : 'light';
  }

  async signalButtonClicked(ev: any) {
    this.analytics('signalButtonClicked');
    let layout = this.operatorsSegment.value.toString().substr(0, 1) + ev.substr(0, 1);
    if (this.layoutState[layout] == true) {
      this.layoutState[layout] = false;
    } else {
      this.layoutState[layout] = true;
    }
    await this.changeLayoutStatus();
  }

  async yearsButtonClicked() {
    this.analytics('yearsButtonClicked');
    for (let i = parseInt(this.min_year); i <= parseInt(this.max_year); i++) {
      this.yearState[i.toString()] = i == this.selected_year;
    }
    await this.changeLayoutStatus();
  }

  async changeLayoutStatus() {
    this.mapLayersService.hidePopup();
    const keys = Object.keys(this.layoutState);
    const years = Object.keys(this.yearState);

    for (let key of keys) {
      for (let layerSource of layers['source-layer']) {
        for (let year of years) {
          let anul = year;
          let column = key + layerSource['column_sufix'];
          let tehnologia = column.substring(1, 2);
          let visibleY = this.yearState[year];
          let layerID = key + '_' + layerSource['source'] + '_' + anul;
          let visible = this.layoutState[key];

          let visibleRoads = true;
          if (this.voiceOrDataSegment.value != 'roads' && layerID.includes('quality')) {
            visibleRoads = false;
          }
          if (this.voiceOrDataSegment.value == 'roads' && !layerID.includes('quality')) {
            visibleRoads = false;
          }
          if (visibleY == false || visibleRoads == false || visible == false) {
            visible = false;
          }

          let filter: any[] = ['all', ['==', 'anul', anul], ['in', 'tehnologia', '*', tehnologia]];

          tehnologia = layerID.substr(1, 1);
          if (tehnologia == 'd' || tehnologia == 'u' || tehnologia == '2') {
            tehnologia = layerID.substr(0, 2);
            filter = ['all', ['==', 'anul', anul], ['in', 'tehnologia', tehnologia, '*'], ['>', column, 0]];
          }

          if (layerID.includes('quality')) {
            filter = ['all', ['==', 'anul', anul], ['>', column + '_c', 9]];
          }

          this.mapLayersService.GetLayer(visible, this.map, layerSource, layerID, filter, column, anul);

          let tempLayer = { source: layerSource['source'], type: 'symbol', minzoom: 6, maxzoom: 20, column_sufix: '' };

          if (this.voiceOrDataSegment.value == 'roads' && layerSource['source'].includes('quality')) {
            let lId = layerSource['source'].split('quality') + '_ref';
            this.mapLayersService.GetLayer(true, this.map, tempLayer, lId, undefined, undefined, anul);
          } else {
            let lId = layerSource['source'].split('quality') + '_ref';
            this.mapLayersService.GetLayer(false, this.map, tempLayer, lId, undefined, undefined, anul);
          }
        }
      }
    }
  }

  searchRoadSelected(localitate) {
    if (localitate.properties.y_max !== undefined) {
      const id = localitate.properties['id'];
      var bound = new mapboxgl.LngLatBounds(
        [localitate.properties['x_min'], localitate.properties['y_max']],
        [localitate.properties['x_max'], localitate.properties['y_min']]
      );

      this.map.fitBounds(bound, {
        padding: 20
      });
      this.map.getStyle().layers.forEach(layer => {
        if (layer.id.includes('quality')) {
          this.map.setFilter(layer.id, ['==', 'id', id]);
        }
      });
    } else {
      this.map.flyTo({
        center: localitate.geometry.coordinates[0],
        zoom: 12,
        essential: true
      });
    }
  }

  async openSearchModal() {
    this.analytics('openSearchModal');

    const modal = await this.modalController.create({
      component: SearchPage
    });

    modal.onDidDismiss().then(data => {
      const localitate = data['data'];

      if (localitate == null) return;
      if (localitate.properties.y_max !== undefined) {
        const id = localitate.properties['id'];
        var bound = new mapboxgl.LngLatBounds(
          [localitate.properties['x_min'], localitate.properties['y_max']],
          [localitate.properties['x_max'], localitate.properties['y_min']]
        );

        this.map.fitBounds(bound, {
          padding: 20
        });
        this.map.getStyle().layers.forEach(layer => {
          if (layer.id.includes('quality')) {
            this.map.setFilter(layer.id, ['==', 'id', id]);
          }
        });
      } else {
        this.map.flyTo({
          center: localitate.geometry.coordinates[0],
          zoom: 12,
          essential: true
        });
      }
    });

    return await modal.present();
  }

  async ionViewDidEnter() {
    this.analytics('ionViewDidEnter');
    const bounds = new mapboxgl.LngLatBounds([23.37, 45.29], [33.0, 48.56]);

    this.map = new mapboxgl.Map({
      container: 'map',
      style: environment.mapStyle,
      center: [28.907089, 47.00367],
      zoom: 7,
      maxZoom: 19,
      minZoom: 7,
      accessToken: environment.accessToken,
      bounds
    });

    this.map.addControl(new mapboxgl.FullscreenControl(), 'bottom-right');
    this.map.addControl(new mapboxgl.ScaleControl());
    this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    this.map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      }),
      'bottom-right'
    );
    await this.showVideoFun();
  }

  async showkpiinfoPage() {
    this.analytics('showkpiinfoPage');

    const modal = await this.modalController.create({
      component: KpiinfoPage,
      cssClass: 'kpiinfo-modal-css',
      componentProps: {
        kpi_id: 99,
        kpi_name: 'Nivelele de referință ale semnalelor radio'
      }
    });

    return await modal.present();
  }

  async showVideoPage() {
    this.analytics('showVideoPage');
    const modal = await this.modalController.create({
      component: VideoPage,
      cssClass: 'video-modal-css',
      componentProps: {}
    });

    return await modal.present();
  }

  analytics(event: any) {
    /*this.ga.startTrackerWithId('G-Y7RMMC3X8P')
     .then(() => {
       console.log('Google analytics is ready now');
        this.ga.trackView(event);
     })
     .catch(e => console.log('Error starting GoogleAnalytics', e));*/
  }

  years_array() {
    let list: number[] = [];
    for (let i = this.min_year; i <= this.max_year; i++) {
      list.push(Number(i.toString()));
    }
    return list;
  }

  ngOnDestroy(): void {
    this.map.remove();
  }
}
