import { Component, Input } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-kpiinfo',
  templateUrl: './kpiinfo.page.html',
  styleUrls: ['./kpiinfo.page.scss']
})
export class KpiinfoPage {
  @Input() kpi_id: string;
  @Input() kpi_name: string;

  constructor(public navParams: NavParams, public modalCtrl: ModalController) {}

  public closeModal() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  showkpiInfo(id_kpi) {
    return id_kpi == this.kpi_id;
  }
}
