import { Injectable } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';

import layersJson from '../../assets/layersNew.json';
import colorsJson from '../../assets/colors.json';

@Injectable({
  providedIn: 'root'
})
export class MapLayersService {
  private static popup: mapboxgl.Popup;
  private qualityLayer: mapboxgl.Layer;
  constructor() {}

  public GetLayer(checked: boolean, map: mapboxgl.Map, layer: any, layerId: string, filter: any[], column: string, anul: string) {
    if (checked) {
      this.AddLayer(map, layer, layerId, filter, column, anul);
    } else {
      this.RemoveLayer(layerId, map);
    }
  }

  private RemoveLayer(layerId: string, map: mapboxgl.Map) {
    if (map.getLayer(layerId)) {
      map.removeLayer(layerId);
    }
  }

  private AddLayer(map: mapboxgl.Map, layer: any, layerId: string, filter: any[], column: string, anul: string) {
    if (map.getLayer(layerId)) {
      return;
    }
    const lSource = layer['source'];
    const source = 'masurari_' + anul;
    const minZoom = layer['minzoom'];
    const maxZoom = layer['maxzoom'];

    if (layer['type'] == 'circle') {
      map.addLayer({
        id: layerId,
        type: 'circle',
        source: source,
        'source-layer': lSource,
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: filter,
        layout: {
          visibility: 'visible'
        },
        paint: {
          'circle-radius': {
            base: 1.75,
            stops: [
              [9, 1],
              [10, 2],
              [12, 3],
              [13, 4],
              [14, 5],
              [20, 20]
            ]
          },
          'circle-color': [
            'match',
            ['get', column],
            colorsJson[0]['level'],
            colorsJson[0]['color'],
            colorsJson[2]['level'],
            colorsJson[2]['color'],
            colorsJson[4]['level'],
            colorsJson[4]['color'],
            colorsJson[6]['level'],
            colorsJson[6]['color'],
            colorsJson[7]['color']
          ],
          'circle-opacity': 0.5
        }
      });
    }

    if (layer['type'] == 'line') {
      map.addLayer({
        id: layerId,
        type: 'line',
        source: source,
        'source-layer': lSource,
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: filter,
        layout: {
          visibility: 'visible'
        },
        paint: {
          'line-color': [
            'case',
            ['<=', ['get', column], colorsJson[7]['level']],
            colorsJson[7]['color'],
            ['<=', ['get', column], colorsJson[6]['level']],
            colorsJson[6]['color'],
            ['<=', ['get', column], colorsJson[5]['level']],
            colorsJson[5]['color'],
            ['<=', ['get', column], colorsJson[4]['level']],
            colorsJson[4]['color'],
            ['<=', ['get', column], colorsJson[3]['level']],
            colorsJson[3]['color'],
            ['<=', ['get', column], colorsJson[2]['level']],
            colorsJson[2]['color'],
            ['<=', ['get', column], colorsJson[1]['level']],
            colorsJson[1]['color'],
            ['<=', ['get', column], colorsJson[0]['level']],
            colorsJson[0]['color'],
            colorsJson[7]['color']
          ],
          'line-width': 4,
          'line-opacity': 0.5
        }
      });
    }

    if (layer['type'] == 'fill') {
      map.addLayer({
        id: layerId,
        type: 'fill',
        source: source,
        'source-layer': lSource,
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: filter,
        layout: {
          visibility: 'visible'
        },
        paint: {
          'fill-color': [
            'case',
            ['<=', ['get', column], colorsJson[7]['level']],
            colorsJson[7]['color'],
            ['<=', ['get', column], colorsJson[6]['level']],
            colorsJson[6]['color'],
            ['<=', ['get', column], colorsJson[5]['level']],
            colorsJson[5]['color'],
            ['<=', ['get', column], colorsJson[4]['level']],
            colorsJson[4]['color'],
            ['<=', ['get', column], colorsJson[3]['level']],
            colorsJson[3]['color'],
            ['<=', ['get', column], colorsJson[2]['level']],
            colorsJson[2]['color'],
            ['<=', ['get', column], colorsJson[1]['level']],
            colorsJson[1]['color'],
            ['<=', ['get', column], colorsJson[0]['level']],
            colorsJson[0]['color'],
            colorsJson[7]['color']
          ],
          'fill-opacity': 0.5
        }
      });
    }

    if (layer['type'] == 'symbol') {
      map.addLayer({
        id: layerId,
        type: 'symbol',
        source: source,
        'source-layer': lSource,
        layout: {
          'symbol-placement': 'line-center',
          'text-font': ['Open Sans Regular'],
          'text-field': '{ref}',
          visibility: 'visible',
          'text-size': 8,
          'text-rotate': -4,
          'symbol-spacing': 1
        },
        paint: {
          'text-translate': [0, -20]
        }
      });
    }

    if (layer['type'] != 'symbol') {
      map.on('mouseenter', layerId, event => (map.getCanvas().style.cursor = 'pointer'));
      map.on('mouseleave', layerId, event => (map.getCanvas().style.cursor = ''));
      map.on('click', layerId, event => this.onMarkerClicked(layerId, event, map));
    }
  }

  private onMarkerClicked(layerId: string, event, map: mapboxgl.Map) {
    const features = map.queryRenderedFeatures(event.point);
    let temp_descr =
      '<H1_><span>NAME_OPERATOR</span></H1_>\
          <ion-item>\
					<ion-icon name="ICON_NAME" style="color: #FFFFFF"></ion-icon>\
					<ion-label >&nbsp;&nbsp;APRECIERE_OPERATOR</ion-label>\
          </ion-item>';

    const coordinates = [event.lngLat.lng, event.lngLat.lat];

    let column = layerId.substr(0, 2);
    let result = layersJson.Layers.filter(entry => entry.properties['name'] === column);

    if (features[0].layer['source-layer'].includes('quality')) {
      if (features[0].layer['source-layer'].includes('qualityroads')) {
        temp_descr +=
          '<p>&nbsp;&nbsp;&nbsp;&nbsp;Drumul: ' +
          features[0].properties['ref'] +
          ' (' +
          features[0].properties['len'].toFixed(3) +
          ' km)</p>';
      } else {
        temp_descr += '<p>&nbsp;&nbsp;&nbsp;&nbsp;Localitatea: ' + features[0].properties['ref'] + '</p>';
      }

      let pr = (features[0].properties[column + '_pr'] * 100).toFixed(2);
      temp_descr += '<p>&nbsp;&nbsp;&nbsp;&nbsp;Acoperire: ' + pr + '%</p>';

      let cnt = features[0].properties[column + '_c'].toFixed(0);
      temp_descr += '<p>&nbsp;&nbsp;&nbsp;&nbsp;Cantitatea de puncte: ' + cnt + '</p>';
    } else if (!features[0].layer['source-layer'].includes('masurari_full')) {
      column = column + '_avg';
      let cnt = features[0].properties[column.split('_avg').join('_cnt')].toFixed(0);
      if (cnt > 0) {
        temp_descr += '<p>&nbsp;&nbsp;&nbsp;&nbsp;Cantitatea de puncte: ' + cnt + '</p>';
      }
    } else {
      const realValue = features[0].properties[result[0].properties['real_name']];
      if (realValue !== undefined && realValue != -1) {
        temp_descr += '<p>&nbsp;&nbsp;&nbsp;&nbsp;Valoarea (dBm): ' + realValue.toFixed(2) + '</p>';
      }
    }

    let level = 0;
    if (features[0].properties[column] != undefined) {
      level = features[0].properties[column].toFixed(2);
    }

    let color = colorsJson.filter(entry => entry['level'] >= level);
    color = color[color.length - 1];
    let anul = features[0].properties['anul'];
    temp_descr += '<p>&nbsp;&nbsp;&nbsp;&nbsp;Măsurări : ' + anul + '</p>';

    const nume = result[0].properties['title'];

    let description = temp_descr;
    description = description.split('H1_').join('h1_' + column.substr(0, 1));
    description = description.split('#FFFFFF').join(color['color']);
    description = description.split('NAME_OPERATOR').join(nume);
    if (column.substr(1, 1) == '2' || column.substr(1, 1) == '3' || column.substr(1, 1) == '4') {
      description = description.split('ICON_NAME').join('radio-outline');
      description = description.split('APRECIERE_OPERATOR').join(color['name']);
    } else {
      description = description.split('ICON_NAME').join('ellipse');
      description = description.split('dBm').join('kbps');
      description = description.split('APRECIERE_OPERATOR').join(color['name_date']);
    }
    while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
    }
    if (MapLayersService.popup != null) {
      MapLayersService.popup.remove();
    }
    if (features[0].properties.y_max !== undefined) {
      const id = features[0].properties['id'];
      const typeLayer = features[0].layer.type;
      map.getStyle().layers.forEach(layer => {
        if (layer.id.includes('quality')) {
          let roadColumnC = layer.id.substr(0, 2) + '_c';
          let anul = layer.id.substr(-4, 4);
          map.setFilter(layer.id, ['all', ['==', 'anul', anul], ['>', roadColumnC, 9]]);

          if (layer.type == 'line') {
            map.setPaintProperty(layer.id, layer.type + '-width', 4);
          }

          map.setPaintProperty(layer.id, layer.type + '-opacity', 0.5);

          let layerPuncte = layer.id.split('qualityroads').join('masurari_full');
          layerPuncte = layerPuncte.split('qualitylocality').join('masurari_full');
          this.GetLayer(false, map, undefined, layerPuncte, undefined, undefined, anul);
        }
      });

      if (typeLayer == 'line') {
        map.setPaintProperty(features[0].layer['id'], typeLayer + '-width', ['match', ['get', 'id'], id, 12, 4]);
      }

      map.setPaintProperty(features[0].layer['id'], typeLayer + '-opacity', ['match', ['get', 'id'], id, 0.2, 0.5]);

      let layerPuncte = features[0].layer['id'].split('qualityroads').join('masurari_full');
      layerPuncte = layerPuncte.split('qualitylocality').join('masurari_full');
      const anul = layerPuncte.substr(-4, 4);
      let tehnologia = layerPuncte.substr(1, 1);
      let filter: any[];
      if (tehnologia == 'd' || tehnologia == 'u' || tehnologia == '2') {
        tehnologia = layerPuncte.substr(0, 2);
        filter = ['all', ['==', 'anul', anul], ['==', 'id_drum', id], ['in', 'tehnologia', tehnologia, '*'], ['>', tehnologia, 0]];
      } else {
        filter = ['all', ['==', 'anul', anul], ['==', 'id_drum', id], ['in', 'tehnologia', tehnologia, '*']];
      }

      let layerFull = {
        source: 'masurari_full',
        type: 'circle',
        minzoom: 8,
        maxzoom: 20,
        column_sufix: ''
      };

      this.GetLayer(true, map, layerFull, layerPuncte, filter, column, anul);

      var bound = new mapboxgl.LngLatBounds(
        [features[0].properties['x_min'], features[0].properties['y_max']],
        [features[0].properties['x_max'], features[0].properties['y_min']]
      );

      map.fitBounds(bound, {
        padding: 20
      });
    }

    MapLayersService.popup = new mapboxgl.Popup().setLngLat(event.lngLat).setHTML(description).addTo(map);
  }

  public hidePopup() {
    if (MapLayersService.popup != null) {
      MapLayersService.popup.remove();
    }
  }

  //****************Quality**************************/
  public AddQualityLayer(map: mapboxgl.Map, layerId: string, filter: any[], anul: string) {
    if (map.getLayer(layerId)) map.removeLayer(layerId);

    let findLayer = layersJson['source-layer'].filter(entry => entry['source'] === layerId);
    let layer = findLayer[0];

    if (!layer) {
      layer = { source: layerId, type: 'circle', minzoom: 6, maxzoom: 20, column_sufix: '' };
    }

    if (map.getLayer(layerId)) {
      this.UpdateQualityFilter('visible', map, layerId, filter);
      return;
    }

    const lSource = layer['source'];
    const source = 'masurari_' + anul;
    const minZoom = layer['minzoom'];
    const maxZoom = layer['maxzoom'];

    if (layer['type'] == 'circle') {
      map.addLayer({
        id: layerId,
        type: 'circle',
        source: source,
        'source-layer': lSource,
        filter: filter,
        layout: {
          visibility: 'visible'
        },
        paint: {
          'circle-radius': {
            base: 2,
            stops: [
              [9, 4],
              [10, 5],
              [12, 7],
              [13, 9],
              [14, 10],
              [20, 20]
            ]
          },
          'circle-color': 'black',
          'circle-opacity': 0.8
        }
      });
    }

    if (layer['type'] == 'line') {
      map.addLayer({
        id: layerId,
        type: 'line',
        source: source,
        'source-layer': lSource,
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: filter,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
          visibility: 'visible'
        },
        paint: {
          'line-color': '#2b8cbd',
          'line-width': 5
        }
      });
    }

    if (layer['type'] == 'fill') {
      map.addLayer({
        id: layerId,
        type: 'fill',
        source: source,
        'source-layer': lSource,
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: filter,
        layout: {
          visibility: 'visible'
        },
        paint: {
          'fill-color': '#088',
          'fill-opacity': 0.2
        }
      });
    }

    map.on('mouseenter', layerId, event => (map.getCanvas().style.cursor = 'pointer'));
    map.on('mouseleave', layerId, event => (map.getCanvas().style.cursor = ''));
  }

  public UpdateQualityFilter(visible: string, map: mapboxgl.Map, layerId: string, filter: any[]) {
    if (!map.getLayer(layerId)) return;

    map.setFilter(layerId, filter);
    map.setLayoutProperty(layerId, 'visibility', visible);
  }
}
