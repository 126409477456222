import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { KpiinfoPageRoutingModule } from './kpiinfo-routing.module';

import { KpiinfoPage } from './kpiinfo.page';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MatIconModule,
    KpiinfoPageRoutingModule
  ],
  declarations: [KpiinfoPage]
})
export class KpiinfoPageModule { }
