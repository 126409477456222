import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { KpiinfoPage } from './kpiinfo.page';

const routes: Routes = [
  {
    path: '',
    component: KpiinfoPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class KpiinfoPageRoutingModule {}
