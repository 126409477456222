import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureCollection } from 'geojson';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalityDetails, RegionDetails } from '../regions-technology/regions-helper';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  constructor(private http: HttpClient) {}

  insertVisit(page_in: string): Observable<unknown> {
    return this.http
      .get('  https://harta.anrceti.md/harta_insert_visitators_api.php?', { params: { page: page_in } })
      .pipe(catchError(this.handleError));
  }

  getLocalitiesBL(an: string): Observable<LocalityDetails[]> {
    return this.http
      .get<LocalityDetails[]>('https://harta.anrceti.md/harta_statistica_abonati_localitati_api.php', { params: { an } })
      .pipe(catchError(this.handleError));
  }

  getRegionsBL(an: string): Observable<RegionDetails[]> {
    return this.http
      .get<RegionDetails[]>('https://harta.anrceti.md/harta_statistica_abonati_api.php', { params: { an } })
      .pipe(catchError(this.handleError));
  }

  getBlAvailableYears(): Observable<string[]> {
    // return of(['2022']).pipe(delay(1500));
    return this.http.get<string[]>('https://harta.anrceti.md/harta_statistica_abonati_ani_api.php').pipe(catchError(this.handleError));
  }

  getRegionsGeojson(): Observable<FeatureCollection> {
    return this.http.get<any>('assets/json/md.json').pipe(catchError(this.handleError));
  }

  getLocalitiesGeojson(): Observable<FeatureCollection> {
    return this.http.get<any>('assets/json/md_localities.json').pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error);
    } else {
      console.error(`Backend returned code ${error.status},` + `body was: ${error.error}`);
    }
    return throwError(error);
  }
}
