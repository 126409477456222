<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title slot="start" style="max-width: 90%">{{kpi_name}}</ion-title>
    <ion-buttons slot="end">
      <button (click)="closeModal()" class="button icon clear">
        <mat-icon>close</mat-icon>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!--1. Rata de blocare a apelurilor (RBA) -->
  <div class="info" *ngIf="showkpiInfo(1)">
    <p>
      <strong>Rata de blocare a apelurilor (RBA)</strong>
      reprezintă raportul procentual dintre numărul apelurilor blocate
      (nereuşite) şi numărul total de încercări de apel. Acest parametru redă
      probabilitatea insuccesului în efectuarea apelurilor de către utilizatorul
      final.
    </p>
    <p>
      Un apel blocat (nereuşit) este un apel către un număr de telefon valid,
      corect format, în urma căruia nu se obţine ton de apel sau semnal de
      răspuns timp de 40 de secunde din momentul când informaţia de adresă
      (numărul de telefon către care este destinat apelul) este integral
      recepţionată de reţea.
    </p>
    <p>Aprecierea nivelurilor ratei de blocare a apelurilor (RBA), %</p>
    <ion-grid>
      <ion-row>
        <ion-col>RBA &le; 0,7</ion-col>
        <ion-col>
          <ion-chip color="success">Foarte bun</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>0,7 &lt; RBA &le; 1,4</ion-col>
        <ion-col>
          <ion-chip color="warning">Bun</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>1,4 &lt; RBA &le; 2</ion-col>
        <ion-col>
          <ion-chip>Satisfăcător</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>RBA &gt; 2</ion-col>
        <ion-col>
          <ion-chip color="danger">Nesatisfăcător</ion-chip>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <!--2. Rata apelurilor întrerupte (RAÎ) -->
  <div class="info" *ngIf="showkpiInfo(2)">
    <p>
      <strong>Rata apelurilor întrerupte (RAÎ)</strong>
      reprezintă raportul procentual al numărului apelurilor reuşite dar
      prematur întrerupte din cauza erorilor tehnice de reţea într-un interval
      de 120 de secunde din momentul obţinerii primului ton de apel sau
      semnalului de răspuns, la numărul total de apeluri reuşite.
    </p>
    <p>
      Un apel reuşit este un apel către un număr de telefon valid, corect
      format, în urma căruia se obţine ton de apel sau semnal de răspuns în
      intervalul maxim de 40 secunde din momentul când informaţia de adresă
      (numărul de telefon către care este destinat apelul) este integral
      recepţionată de reţea.
    </p>
    <p>Aprecierea nivelurilor ratei apelurilor întrerupte (RAÎ), %</p>

    <ion-grid>
      <ion-row>
        <ion-col> RBÎ &le; 0,7</ion-col>
        <ion-col>
          <ion-chip color="success">Foarte bun</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> 0,7 &lt; RBÎ &le; 1,4</ion-col>
        <ion-col>
          <ion-chip color="warning">Bun</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> 1,4 &lt; RBÎ &le; 2</ion-col>
        <ion-col>
          <ion-chip>Satisfăcător</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> RBÎ &gt; 2</ion-col>
        <ion-col>
          <ion-chip color="danger">Nesatisfăcător</ion-chip>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <!--3. Timpul de stabilire a apelului (TSA)  -->
  <div class="info" *ngIf="showkpiInfo(3)">
    <p>
      <strong>Timpul de stabilire a apelului (TSA) </strong>
    </p>

    <p>
      este perioada care începe din momentul recepţionării de către reţea a
      informaţiei necesare şi suficiente pentru stabilirea unui apel şi se
      termină în momentul recepţionării de către partea apelantă a primului ton
      de apel sau al semnalului de răspuns. Timpul de stabilire a apelului
      reuşit (TSA) se evaluează conform formulei:
    </p>
    <p>TSA [s] = T2 - T1, unde:</p>
    <p>
      T1
      <strong
        >- momentul recepţionării de către reţea a informaţiei necesare şi
        suficiente pentru stabilirea unui apel;
      </strong>
    </p>
    <p>
      T2
      <strong
        >- momentul recepţionării de către partea apelantă a primului ton de
        apel sau al semnalului de răspuns.
      </strong>
    </p>
    <p></p>
    <p>
      Valoarea medie a timpului de stabilire a apelurilor reuşite (TSA), în
      secunde, se apreciază, după cum urmează:
    </p>

    <ion-grid>
      <ion-row>
        <ion-col> TSA &le; 5</ion-col>
        <ion-col>
          <ion-chip color="success">Foarte bun</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> 5 &lt; TSA &le; 10</ion-col>
        <ion-col>
          <ion-chip color="warning">Bun</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> 10 &lt; TSA &le; 20</ion-col>
        <ion-col>
          <ion-chip>Satisfăcător</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> TSA &gt; 20</ion-col>
        <ion-col>
          <ion-chip color="danger">Nesatisfăcător</ion-chip>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <!--4. Rata mesajelor scurte SMS (RSMS)   -->
  <div class="info" *ngIf="showkpiInfo(4)">
    <p>
      <strong>Rata mesajelor scurte SMS (RSMS) </strong>
    </p>

    <p>
      livrate cu succes reprezintă raportul procentual dintre numărul mesajelor
      scurte SMS trimise de către un echipament terminal mobil sursă şi livrate
      către un echipament terminal mobil de destinaţie într-o perioadă de 60 de
      secunde din momentul expedierii mesajului şi numărul total de mesaje
      scurte SMS expediate de către echipamentul terminalul mobil sursă către
      echipamentul terminalul mobil de destinaţie. Rata mesajelor scurte livrate
      cu succes (RSMS) este evaluată conform formulei: RSMS (%) = NSMSsucces /
      NSMStotal * 100%, unde:
    </p>
    <p>
      NSMSsucces -
      <strong
        >numărul mesajelor scurte SMS trimise şi recepţionate cu succes în
        intervalul maxim de 60 de secunde din momentul expedierii mesajului;
      </strong>
    </p>
    <p>
      NSMStotal -
      <strong>numărul total de mesaje scurte SMS expediate.</strong>
    </p>
    <p></p>
    <p>
      Nivelul ratei mesajelor scurte SMS livrate cu succes (RSMS), %, se
      apreciază, după cum urmează:
    </p>

    <ion-grid>
      <ion-row>
        <ion-col> RSMS &ge; 98,3</ion-col>
        <ion-col>
          <ion-chip color="success">Foarte bun</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> 96,6 &le; RSMS &lt; 98,3</ion-col>
        <ion-col>
          <ion-chip color="warning">Bun</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> 95 &le; RSMS &lt; 96,6</ion-col>
        <ion-col>
          <ion-chip>Satisfăcător</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> RSMS &lt; 95</ion-col>
        <ion-col>
          <ion-chip color="danger">Nesatisfăcător</ion-chip>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <!--5. Valoarea medie a notei MOS-LQO pe rețea -->
  <div class="info" *ngIf="showkpiInfo(5)">
    <p>
      <strong>Valoarea medie a notei MOS-LQO pe rețea </strong>
    </p>

    <p>
      reprezintă scorul mediu al unor aprecieri pentru calitatea de transmisie a
      semnalului vocal. Acest parametru cuantifică perceptibilitatea
      conversaţiei în timpul unui apel vocal. Ambele direcţii de comunicare sunt
      evaluate şi sunt luate în considerare numai apelurile care se termină în
      mod normal.
    </p>
    <p></p>
    <p>
      Pentru fiecare apel vocal se acordă o singură apreciere din cinci
      aprecieri posibile şi fiecărei aprecieri i se atribuie o valoare numerică
      întreagă, o notă, conform aprecierii:
    </p>

    <ion-grid>
      <ion-row>
        <ion-col> MOS-LQO &ge; 4</ion-col>
        <ion-col>
          <ion-chip color="success">Foarte bun</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>4 &gt; MOS-LQO &ge; 3,5</ion-col>
        <ion-col>
          <ion-chip color="warning">Bun</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> 3,5 &gt; MOS-LQO &ge; 3</ion-col>
        <ion-col>
          <ion-chip>Satisfăcător</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> MOS-LQO &lt; 3</ion-col>
        <ion-col>
          <ion-chip color="danger">Nesatisfăcător</ion-chip>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <!--6. Viteza de transfer al datelor în direcţia descendentă/download (VDL)-->
  <div class="info" *ngIf="showkpiInfo(6)">
    <p>
      <strong
        >Viteza de transfer al datelor în direcţia descendentă/download (VDL)
      </strong>
    </p>

    <p>
      în cadrul unei probe/sesiuni de date finalizate cu succes se evaluează
      conform formulei:
    </p>
    <p><strong>VDLi (kbps)</strong> = DDi (kb) / &Delta;td (s), unde:</p>
    <p>
      <strong>DDi</strong> - volumul de date descărcate (download) pe
      echipamentul terminal mobil de test între momentul în care transferul de
      date începe şi momentul în care măsurarea se opreşte la sfârşitul
      intervalului de timp predefinit &Delta;td;
    </p>
    <p>
      <strong>&Delta;td - </strong>
      intervalul de timp predefinit pentru efectuarea măsurărilor, care se
      scurge din momentul primirii de către echipamentul terminal mobil de test
      al primului pachet de date al fişierului de test descărcat de pe serverul
      de test dedicat;
    </p>
    <p><strong>i</strong> - proba în care a fost evaluată VDLi.</p>
    <p></p>
    <p>
      Nivelul vitezei medii de transfer al datelor descendent/download (VDL),
      kbps, se apreciază, după cum urmează:
    </p>

    <ion-grid>
      <ion-row>
        <ion-col> VDL &ge; 10240</ion-col>
        <ion-col>
          <ion-chip color="success"> Foarte bun </ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> 10240 &gt; VDL &ge; 6144</ion-col>
        <ion-col>
          <ion-chip color="warning"> Bun </ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> 6144 &gt; VDL &ge; 2048</ion-col>
        <ion-col>
          <ion-chip color="dark"> Satisfăcător </ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> VDL &lt; 2048</ion-col>
        <ion-col>
          <ion-chip color="danger"> Nesatisfăcător </ion-chip>
        </ion-col>
      </ion-row>
    </ion-grid>

    <p>
      ***Viteza medie de transfer al datelor reprezintă rata de transfer al
      datelor în direcţia descendentă (download) şi în direcţia ascendentă
      (upload) măsurată pe un interval de timp predefinit &Delta;td, conform
      conceptului timp fix de transfer de date (Concept of FDTT-QoS - Fixed Data
      Transfer Time QoS) definit în SM ETSI TR 102 678. Condiţia prealabilă
      pentru măsurarea acestui parametru este accesul la reţeaua mobilă şi la
      serviciul de transfer al datelor. Conexiunea trebuie să fie activă la
      sfârşitul intervalului de timp predefinit pentru efectuarea unei probe
      (&Delta;td = 30 s).
    </p>
  </div>

  <!--7. Viteza de transfer al datelor în direcţia ascendentă/upload (VUL) -->
  <div class="info" *ngIf="showkpiInfo(7)">
    <p>
      <strong
        >Viteza de transfer al datelor în direcţia ascendentă/upload (VUL)
      </strong>
    </p>

    <p>
      în cadrul unei probe/sesiuni de date finalizate cu succes se evaluează
      conform formulei:
    </p>
    <p>VULi (kbps) = DUi (kb) / &Delta;td (s), unde:</p>
    <p>
      DUi
      <strong
        >- volumul de date încărcate (upload) pe serverul de test dedicat între
        momentul în care transferul de date începe şi momentul în care măsurarea
        se opreşte la sfârşitul intervalului de timp predefinit &Delta;td;
      </strong>
    </p>
    <p>
      &Delta;td
      <strong
        >- intervalul de timp predefinit pentru efectuarea măsurărilor, care se
        scurge din momentul
      </strong>
    </p>
    <p>
      <strong
        >transmiterii de către echipamentul terminal de testare a primului
        pachet de date al fişierului de test către serverul de test dedicat;
      </strong>
    </p>
    <p>i -<strong> proba în care a fost evaluată VULi.</strong></p>
    <p></p>
    <p>
      Intervalul de timp predefinit (&Delta;td) nu include perioada necesară
      pentru înregistrarea în reţea, activarea contextului PDP (pentru GSM/UMTS)
      sau Dedicated EPS Bearer Setup (pentru LTE), sau
    </p>
    <p>autentificarea pe serverul de test.</p>
    <p></p>
    <p>
      La măsurarea vitezei de transfer al datelor în direcţia ascendentă
      (upload), cu utilizarea protocolului HTTP, serverul de test dedicat
      asigură funcţionalitatea HTTP File Upload.
    </p>
    <p></p>
    <p>
      Nivelul vitezei medii de transfer al datelor ascendent/upload (VUL), kbps,
      se apreciază după cum urmează:
    </p>

    <ion-grid>
      <ion-row>
        <ion-col> VUL&ge; 3072</ion-col>
        <ion-col>
          <ion-chip color="success">Foarte bun</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> 3072 &gt; VUL &ge; 1024</ion-col>
        <ion-col>
          <ion-chip color="warning">Bun</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> 1024 &gt; VUL &ge; 512</ion-col>
        <ion-col>
          <ion-chip>Satisfăcător</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> VUL &lt; 512</ion-col>
        <ion-col>
          <ion-chip color="danger">Nesatisfăcător</ion-chip>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <!--8. Timpul de descărcare completă a paginii WEB (TWEB)  -->
  <div class="info" *ngIf="showkpiInfo(8)">
    <p>
      <strong>Timpul de descărcare completă a paginii WEB (TWEB) </strong>
    </p>

    <p>
      reprezintă perioada necesară pentru finalizarea cu succes a unei sesiuni
      HTTP de transfer al datelor aferente paginii WEB descărcate complet (se
      utilizează pagina WEB de referinţă Kepler şi/sau o pagină WEB publică
      specificată). Timpul de descărcare completă a paginii WEB (TWEB) se
      evaluează conform formulei:
    </p>
    <p>TWEBi [s] = Tfinalizare_sesiune - Tstart_sesiune, unde:</p>
    <p>
      Tstart_sesiune<strong>
        - momentul începerii cu succes a sesiunii de transfer al datelor pentru
        descărcarea paginii WEB;
      </strong>
    </p>
    <p>
      Tfinalizare_sesiune
      <strong
        >- momentul în care sesiunea de transfer al datelor pentru descărcarea
        completă a paginii WEB se finalizează;
      </strong>
    </p>
    <p><strong>i - proba în care a fost evaluat TWEBi.</strong></p>
    <p></p>
    <p>
      Nivelul valorii medii a timpului de descărcare completă a paginii WEB
      (TWEB), în secunde, se apreciază după cum urmează:
    </p>

    <ion-grid>
      <ion-row>
        <ion-col> TWEB &le; 5</ion-col>
        <ion-col>
          <ion-chip color="success">Foarte bun</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> 5 &lt; TWEB &le; 10</ion-col>
        <ion-col>
          <ion-chip color="warning">Bun</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> 10 &lt; TWEB &le; 15</ion-col>
        <ion-col>
          <ion-chip>Satisfăcător</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> TWEB &gt;15</ion-col>
        <ion-col>
          <ion-chip color="danger">Nesatisfăcător</ion-chip>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <!--9. Întârzierea de transfer al pachetelor de date (ÎTPD)  -->
  <div class="info" *ngIf="showkpiInfo(9)">
    <p>
      <strong>Întârzierea de transfer al pachetelor de date (ÎTPD) </strong>
    </p>

    <p>
      reprezintă timpul în care pachetul de date este livrat de la echipamentul
      terminal mobil de test la serverul de test sau vice-versa. Timpul în care
      pachetul de date este livrat corespunde cu jumătate din valoarea Round
      Trip Time (RTT) obţinută prin instrumentul PING (ICMP Echo). întârzierea
      de transfer al pachetelor de date (îTPDi) se evaluează conform formulei:
    </p>
    <p>îTPDi (ms) = PINGrtt (ms) / 2, unde:</p>
    <p>
      PINGrtt (Ping Round Trip Time) [ms] = (<strong
        >Tpachet_primit - Tpachet_transmis)(conform Capitolului 6.3.1 din SM
        ETSI TS 102 250-2), unde, respectiv Tpachet_transmis - momentul de timp
        când pachetul de date este transmis de sursă; Tpachet_primit - momentul
        de timp când pachetul de date este primit de sursă;
      </strong>
    </p>
    <p>
      i -
      <strong>măsurarea în care a fost evaluată îTPDi.</strong>
    </p>
    <p></p>
    <p>
      Nivelul valorii medii a întârzierii de transfer al pachetelor de date
      (îTPD), în milisecunde, se apreciază după cum urmează:
    </p>

    <ion-grid>
      <ion-row>
        <ion-col> îTPD &le; 50</ion-col>
        <ion-col>
          <ion-chip color="success">Foarte bun</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> 50 &lt; îTPD &le; 100</ion-col>
        <ion-col>
          <ion-chip color="warning">Bun</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> 100 &lt; îTPD &le; 150</ion-col>
        <ion-col>
          <ion-chip>Satisfăcător</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> îTPD &gt; 150</ion-col>
        <ion-col>
          <ion-chip color="danger">Nesatisfăcător</ion-chip>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <!--10. Rata pierderii de pachete de date (RPPD) -->
  <div class="info" *ngIf="showkpiInfo(10)">
    <p>
      <strong>Rata pierderii de pachete de date (RPPD) </strong>
    </p>

    <p>
      reprezintă raportul procentual dintre numărul de pachete de date expediate
      de echipamentul terminal mobil de test, dar nerecepţionate sau incomplet
      recepţionate la serverul de test (la destinaţie), şi numărul total de
      pachete de date expediate de sursă.
    </p>
    <p>
      Rata pierderii de pachete de date (RPPD) se evaluează conform formulei:
    </p>
    <p>RPPDi (%) = (1 - Npachete_primite / Npachete_trimise)*100, unde:</p>
    <p>
      Npachete_trimise -
      <strong>numărul total de pachete de date expediate de sursă;</strong>
    </p>
    <p>
      Npachete_primite -
      <strong>numărul de pachete de date recepţionate cu succes;</strong>
    </p>
    <p>i - <strong>măsurarea în care a fost evaluată RPPDi.</strong></p>
    <p></p>
    <p>
      Nivelul valorii medii a ratei pierderii de pachete de date (RPPD), %, se
      apreciază după cum urmează:
    </p>

    <ion-grid>
      <ion-row>
        <ion-col> RPPD &le; 1</ion-col>
        <ion-col>
          <ion-chip color="success">Foarte bun</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> 1 &lt; RPPD &le; 2</ion-col>
        <ion-col>
          <ion-chip color="warning">Bun</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> 2 &lt; RPPD &le; 3</ion-col>
        <ion-col>
          <ion-chip>Satisfăcător</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> RPPD &gt; 3</ion-col>
        <ion-col>
          <ion-chip color="danger">Nesatisfăcător</ion-chip>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <!--11. Latenţa în redarea conţinutului video în cadrul unei sesiuni de YouTube Video Streaming (LRCV)  -->
  <div class="info" *ngIf="showkpiInfo(11)">
    <p>
      <strong
        >Latenţa în redarea conţinutului video în cadrul unei sesiuni de YouTube
        Video Streaming (LRCV)
      </strong>
    </p>

    <p>
      este perioada de timp dintre momentul transmiterii cererii de conţinut
      multimedia şi momentul începerii redării conţinutului video (afişarea
      primului cadru) pe echipamentul terminal de testare. Latenţa în redarea
      conţinutului video (LRCV) se evaluează conform formulei:
    </p>
    <p>LRCVi [s] = Tîncepere_redare_video - Tcerere_conţinut_video, unde:</p>
    <p>
      Tcerere_conţinut_video
      <strong
        >- momentul când echipamentul terminal mobil de testare transmite o
        cerere pentru conţinutul video;
      </strong>
    </p>
    <p>
      Tîncepere_redare_video -
      <strong
        >momentul când se afişează primul cadru al conţinutului video pe ecranul
        echipamentului mobil de testare;
      </strong>
    </p>
    <p>i - <strong>proba în care a fost evaluată LRCVi.</strong></p>
    <p></p>
    <p>
      Nivelul valorii medii a latenţei în redarea conţinutului video (LRCV), în
      secunde, se apreciază după cum urmează:
    </p>

    <ion-grid>
      <ion-row>
        <ion-col> LRCV &le; 1</ion-col>
        <ion-col>
          <ion-chip color="success">Foarte bun</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> 1 &lt; LRCV &le; 2</ion-col>
        <ion-col>
          <ion-chip color="warning">Bun</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> 2 &lt; LRCV &le; 3</ion-col>
        <ion-col>
          <ion-chip>Satisfăcător</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> LRCV &gt; 3</ion-col>
        <ion-col>
          <ion-chip color="danger">Nesatisfăcător</ion-chip>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <!--12. Durata întreruperii în redarea conţinutului video (DÎRCV)   -->
  <div class="info" *ngIf="showkpiInfo(12)">
    <p>
      <strong
        >Durata întreruperii în redarea conţinutului video (DÎRCV)
      </strong>
    </p>

    <p>
      agreghează toate întreruperile sau evenimentele de oprire (freezing
      events) a imaginii în timpul redării conţinutului video în cadrul unei
      sesiuni de YouTube Video Streaming care se finalizează în mod normal. Sunt
      luate în considerare doar întreruperile pe care le-ar percepe utilizatorul
      (în cazul în care depăşesc 120 ms conform [SM ETSI TR 101 578]). Durata
      întreruperii în redarea conţinutului video (DîRCVi) se evaluează ca suma
      numărul total al întreruperilor în cadrul unei sesiuni de YouTube Video
      Streaming; care depăşesc 120 ms.
    </p>

    <p>
      Valoarea medie a duratei întreruperii în redarea conţinutului video
      (DÎRCV), măsurate în secunde, nu are valoare de referință.
    </p>
  </div>

  <div class="info" *ngIf="showkpiInfo(99)">
    <p>
      O zonă se consideră acoperită cu servicii mobile atunci când nivelurile de
      semnal radio indică valori mai mari decât valorile de prag minime, care de
      obicei reprezintă valori diferite pentru tehnologiile 2G/GSM, 3G/UMTS şi
      4G/LTE.
    </p>

    <p><strong>1. Pentru reţeaua 2G/GSM:</strong></p>
    <p>Aprecierea nivelurilor de acoperire pentru reţeaua GSM</p>

    <ion-grid>
      <ion-row>
        <ion-col>RxLev, dBm</ion-col>
        <ion-col>Aprecierea</ion-col>
      </ion-row>
      <ion-row>
        <ion-col>-71 &le; RxLev</ion-col>
        <ion-col> <ion-chip color="success">Puternic</ion-chip> </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>-81 &le; RxLev &lt; -71</ion-col>
        <ion-col>
          <ion-chip color="warning">Mediu</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>-92 &le; RxLev &lt; -81</ion-col>
        <ion-col> <ion-chip color="dark">Slab</ion-chip> </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>RxLev &lt; -92</ion-col>
        <ion-col>
          <ion-chip color="danger">Insuficient</ion-chip>
        </ion-col>
      </ion-row>
    </ion-grid>

    <p><strong>2 Pentru reţeaua 3G/UMTS:</strong></p>
    <p>Aprecierea nivelurilor de acoperire pentru reţeaua UMTS</p>

    <ion-grid>
      <ion-row>
        <ion-col>RSCP, dBm</ion-col>
        <ion-col>Aprecierea</ion-col>
      </ion-row>
      <ion-row>
        <ion-col>-85 &le; RSCP</ion-col>
        <ion-col>
          <ion-chip color="success">Puternic</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>-95 &le; RSCP &lt; -85</ion-col>
        <ion-col>
          <ion-chip color="warning">Mediu</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>-106 &le; RSCP &lt; -95</ion-col>
        <ion-col> <ion-chip color="dark">Slab</ion-chip> </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <ion-col>RSCP &lt; -106</ion-col>
          <ion-col>
            <ion-chip color="danger">Insuficient</ion-chip>
          </ion-col>
        </ion-col>
      </ion-row>
    </ion-grid>

    <p><strong>3. Pentru reţeaua 4G/LTE:</strong></p>
    <p>Aprecierea nivelurilor de acoperire pentru reţeaua LTE</p>

    <ion-grid>
      <ion-row>
        <ion-col>RSRP, dBm</ion-col>
        <ion-col>Aprecierea</ion-col>
      </ion-row>
      <ion-row>
        <ion-col>-95 &le; RSRP</ion-col>
        <ion-col>
          <ion-chip color="success">Puternic</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <ion-col>-105 &le; RSRP &lt; -95</ion-col>
          <ion-col>
            <ion-chip color="warning">Mediu</ion-chip>
          </ion-col>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>-115,7 &le; RSRP &lt; -105</ion-col>
        <ion-col> <ion-chip color="dark">Slab</ion-chip> </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>RSRP &lt; -115,7</ion-col>
        <ion-col>
          <ion-chip color="danger">Insuficient</ion-chip>
        </ion-col>
      </ion-row>
    </ion-grid>

    <p><strong>4. Pentru serviciul date:</strong></p>
    <p>Aprecierea nivelurilor vitezei medii de transfer al datelor</p>

    <ion-grid>
      <ion-row>
        <ion-col>Viteza medie de transfer al datelor (VDL/VUL), kbps</ion-col>
      </ion-row>
      <ion-row>
        <ion-col>Descendent (download)</ion-col>
        <ion-col>Ascendent (upload)</ion-col>
        <ion-col>Aprecierea</ion-col>
      </ion-row>
      <ion-row>
        <ion-col>VDL≥ 10240</ion-col>
        <ion-col>VUL≥ 3072</ion-col>
        <ion-col>
          <ion-chip color="success">Bun</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>10240 &gt; VDL &ge; 6144</ion-col>
        <ion-col>3072 &gt; VUL&ge; 1024</ion-col>
        <ion-col>
          <ion-chip color="warning">Mediu</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>6144 &gt; VDL&ge; 2048</ion-col>
        <ion-col>1024 &gt;VUL &ge; 512</ion-col>
        <ion-col>
          <ion-chip color="dark">Satisfacator</ion-chip>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>VDL&lt; 2048</ion-col>
        <ion-col>VUL&lt; 512</ion-col>
        <ion-col>
          <ion-chip color="danger">Nesatisfacator</ion-chip>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
