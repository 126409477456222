import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { HomePage } from './home.page';
import { MaterialModule } from '../material.module';
import { SearchPageModule } from '../modal/search/search.module';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from '@angular/material/icon';

//import { SearchPage } from '../modal/search/search.page';
//import { LegendPage } from '../modal/legend/legend.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MaterialModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    SearchPageModule,
    RouterModule.forChild([
      {
        path: '',
        component: HomePage
      }
    ])
  ],
  //declarations: [HomePage,SearchPage,LegendPage],
  //exports: [HomePage,SearchPage,LegendPage],
  //entryComponents: [SearchPage,LegendPage]
  declarations: [HomePage],
  exports: [HomePage]//,
  //entryComponents: [SearchPage,LegendPage]
})
export class HomePageModule { }
