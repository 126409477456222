import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';


@Component({
  selector: 'app-video',
  templateUrl: './video.page.html',
  styleUrls: ['./video.page.scss'],
})


export class VideoPage implements OnInit {

  title:string;
  lang:string;

  constructor(public navParams: NavParams,
    private storage: Storage,
    private platform: Platform,
    public modalCtrl: ModalController) { 
      //console.log("url ",this.video_url);
      this.platform.ready().then(() => {
        this.getLanguage();
      });
    }

  ngOnInit() {
  }

  
  public closeModal() {

    var video_response = {
      showVideo: false
    };


    this.storage.set('VIDEO', video_response).then((response) => {
      console.log("response video",response);
    });


    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  
  getLanguage() {
    this.storage.get('LANG').then((response) => {
      if (response) {
        console.log("response",response);
        this.lang = response.lang;
        console.log("lang",this.lang);
      }else{
        this.changeURLLang("ro");
      }
    });
  }

  async changeURLLang(ev: any) {

    console.log("ev",ev);
    if (ev.detail != undefined){
    console.log("lang before",ev.detail.value);


      this.lang = ev.detail.value;
    }else{
      this.lang = "ro";
    }
    console.log("lang after",this.lang);

    var lang_response = {
      lang: this.lang
    };


    this.storage.set('LANG', lang_response).then((response) => {
      console.log("response",response);
    });

  }


}
