import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage';
import { AuthGuard } from './guards/auth.guard';
import { AuthenticationService } from './services/authentication.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HomePageModule } from './home/home.module';
import { LegendPageModule } from './modal/legend/legend.module';
import { SearchPageModule } from './modal/search/search.module';
import { MaterialModule } from './material.module';
import { KpiinfoPageModule } from './modal/kpiinfo/kpiinfo.module';
import { RegionsPageModule } from './regions-technology/regions.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    NgxDatatableModule,
    FormsModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HomePageModule,
    RegionsPageModule,
    LegendPageModule,
    SearchPageModule,
    IonicStorageModule.forRoot(),
    BrowserAnimationsModule,
    MaterialModule,
    KpiinfoPageModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    AuthGuard,
    AuthenticationService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
