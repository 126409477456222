<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-toggle>
        <button class="button icon clear">
          <mat-icon>menu</mat-icon>
        </button>
      </ion-menu-toggle>
      <ion-title> Harta serviciilor în bandă largă</ion-title>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div id="map"></div>
  <div class="filter">
    <div class="inline">
      <ion-select interface="popover" value="2022" [formControl]="filterYearControl" (ionChange)="changeYear()">
        <ion-select-option [value]="year" *ngFor="let year of filterYears | async"> Anul: {{year}} </ion-select-option>
      </ion-select>
      <ion-segment slot="end" mode="ios" class="value-type-select" (ionChange)="changeValueType()" [formControl]="filterTypeValueControl">
        <ion-segment-button value="abonati">
          <ion-label>Abonați</ion-label>
        </ion-segment-button>
        <ion-segment-button value="ponderea">
          <ion-label>Pondere</ion-label>
        </ion-segment-button>
      </ion-segment>
    </div>

    <ion-segment slot="end" mode="ios" class="technology-select" (ionChange)="changeTechnology()" [formControl]="filterTechnologyControl">
      <ion-segment-button value="FTTx">
        <ion-label>FTTx</ion-label>
      </ion-segment-button>
      <ion-segment-button value="xDSL">
        <ion-label>xDSL</ion-label>
      </ion-segment-button>
      <ion-segment-button value="Cablu">
        <ion-label>Cablu</ion-label>
      </ion-segment-button>
      <ion-segment-button value="Altele">
        <ion-label>Altele</ion-label>
      </ion-segment-button>
      <ion-segment-button value="FTTx,xDSL,Cablu,Altele">
        <ion-label>Toate</ion-label>
      </ion-segment-button>
    </ion-segment>
  </div>
  <div class="chart mat-elevation-z3">
    <ion-spinner name="crescent" color="primary" si *ngIf="isLoading"></ion-spinner>
    <ngx-charts-advanced-pie-chart
      *ngIf="!isLoading"
      [valueFormatting]="valueFormat"
      [percentageFormatting]="percentageFormat"
      [animations]="false"
      [results]="regionsLegend"
      [scheme]="colorScheme"
      legendPosition="below"
      legendTitle="Tehnologii">
    </ngx-charts-advanced-pie-chart>
  </div>
</ion-content>
