import { Component } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { Router } from '@angular/router';
import { VideoPage } from './modal/video/video.page';

export interface MenuItem {
  title: string;
  url: string;
  icon: string;
  external?: boolean;
}
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  menuItems: MenuItem[];

  constructor(private modalController: ModalController, private router: Router) {
    this.sideMenu();
  }

  async showVideoModal() {
    const modal = await this.modalController.create({
      component: VideoPage,
      cssClass: 'video-modal-css',
      componentProps: {}
    });

    await modal.present();
  }

  menuItemClicked(menuItem: MenuItem) {
    if (menuItem.external) {
      window.open(menuItem.url, '_blank');
    } else {
      this.router.navigateByUrl(menuItem.url);
    }
  }

  sideMenu() {
    this.menuItems = [
      {
        title: 'Acoperire',
        url: '/home',
        icon: 'coverage'
      },
      // {
      //   title: 'Servicii în bandă largă',
      //   url: '/regions',
      //   icon: 'wifi'
      // },
      {
        title: 'Despre hartă',
        url: '/about',
        icon: 'about'
      },
      {
        title: 'Parametrii de calitate',
        url: '/qualityonroads',
        icon: 'roads'
      },
      {
        title: 'Tutorial',
        url: '/tutorial',
        icon: 'help-circle-outline'
      },
      {
        title: 'Cadrul de reglementare',
        url: '/regulatory',
        icon: 'regulatory'
      },
      {
        title: 'Statistica',
        url: '/statistics',
        icon: 'stats-chart-outline'
      },
      {
        title: 'Măsurători',
        url: '/measurements',
        icon: 'measurements'
      },
      {
        title: 'Informații utile',
        url: '/useful',
        icon: 'information-circle-outline'
      },
      {
        title: 'Comparator de oferte',
        url: 'https://comparator.anrceti.md/',
        icon: 'git-compare-outline',
        external: true
      },
      {
        title: 'Vizualizări',
        url: '/visit-statistics',
        icon: 'pie-chart-outline'
      }
    ];
  }
}
