import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, IonSearchbar } from '@ionic/angular';
import { LocalitatiService } from 'src/app/services/localitati.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.page.html',
  styleUrls: ['./search.page.scss']
})
export class SearchPage implements OnInit {
  @ViewChild('searchBar', { static: true }) searchBar: IonSearchbar;

  public searchTerm: string = '';
  public items: any;
  public selectedItem: any;

  constructor(private modalController: ModalController, private localitatiService: LocalitatiService) {}

  ngOnInit() {
    this.setFilteredItems('');
    setTimeout(() => this.searchBar.setFocus(), 100);
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  setFilteredItems(searchTerm) {
    this.items = this.localitatiService.filterItems(searchTerm);
  }

  localitateClick(item) {
    this.selectedItem = item;
    this.modalController.dismiss(this.selectedItem);
  }
}
