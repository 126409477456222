import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';


const routes: Routes = [
  //{ path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '', redirectTo: 'home', pathMatch: 'full' },//new
  //{ path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    //canActivate: [AuthGuard]
  },  
  {
    path: 'regions',
    loadChildren: () => import('./regions-technology/regions.module').then(m => m.RegionsPageModule),
    //canActivate: [AuthGuard]
  },
  {
    path: 'qualityonroads',
    loadChildren: () => import('./qualityonroads/qualityonroads.module').then(m => m.QualityonroadsPageModule),
    //canActivate: [AuthGuard]
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./tutorial/tutorial.module').then(m => m.TutorialPageModule),
    //canActivate: [AuthGuard]
  },
  {
    path: 'regulatory',
    loadChildren: () => import('./regulatory/regulatory.module').then(m => m.RegulatoryPageModule),
    //canActivate: [AuthGuard]
  },
  {
    path: 'usefullinks',
    loadChildren: () => import('./usefullinks/usefullinks.module').then(m => m.UsefullinksPageModule),
    //canActivate: [AuthGuard]
  },
  {
    path: 'statistics',
    loadChildren: () => import('./statistics/statistics.module').then(m => m.StatisticsPageModule),
    //canActivate: [AuthGuard]
  },
  {
    path: 'visit-statistics',
    loadChildren: () => import('./visit-statistics/visit-statistics.module').then(m => m.VisitStatisticsPageModule),
    //canActivate: [AuthGuard]
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then(m => m.AboutPageModule),
    //canActivate: [AuthGuard]
  },
  {
    path: 'kpiinfo',
    loadChildren: () => import('./modal/kpiinfo/kpiinfo.module').then(m => m.KpiinfoPageModule),
    //canActivate: [AuthGuard]
  },
  {
    path: 'measurements',
    loadChildren: () => import('./measurements/measurements.module').then(m => m.MeasurementsPageModule),
    //canActivate: [AuthGuard]
  },
  {
    path: 'useful',
    loadChildren: () => import('./useful/useful.module').then(m => m.UsefulPageModule),
    //canActivate: [AuthGuard]
  },
  //{ path: '**', redirectTo: 'login', pathMatch: 'full' },

  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminPageModule),
    //canActivate: [AuthGuard]
    canLoad: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    // canLoad: [IntroGuard, AutoLoginGuard] // Check if we should show the introduction or forward to inside
  },

  { path: '**', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'measurements',
    loadChildren: () => import('./measurements/measurements.module').then(m => m.MeasurementsPageModule)
  },
  {
    path: 'video',
    loadChildren: () => import('./modal/video/video.module').then(m => m.VideoPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
