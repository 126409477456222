<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-toggle>
        <button class="button icon clear">
          <mat-icon>menu</mat-icon>
        </button>
      </ion-menu-toggle>

      <ion-title> Acoperire cu semnal radio </ion-title>
    </ion-buttons>

    <button class="button primary" slot="end" (click)="openSearchModal()">
      <ion-icon name="search-outline"></ion-icon>
      Cauta localitatea/drumul
    </button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div #map id="map"></div>

  <div horizontal="end" slot="fixed" vertical="top" class="year-filter">
    <ion-item class="year-select rounded mat-elevation-z3">
      <ion-select interface="popover" placeholder="Selectează anul" [(ngModel)]="selected_year" (ionChange)="yearsButtonClicked()">
        <ion-select-option [value]="year" *ngFor="let year of years_array()"> Anul: {{year}} </ion-select-option>
      </ion-select>
    </ion-item>
  </div>

  <div class="operator-select" horizontal="left" slot="fixed" vertical="top">
    <ion-segment mode="ios" #voiceOrDataSegment value="voice" (ionChange)="voiceOrDataChanged()">
      <ion-segment-button mode="ios" value="voice">
        <mat-icon>signal_cellular_alt</mat-icon>
        <ion-label>Nivel semnal</ion-label>
      </ion-segment-button>
      <ion-segment-button mode="ios" value="data">
        <mat-icon>swap_vert</mat-icon>
        <ion-label>Date</ion-label>
      </ion-segment-button>
      <ion-segment-button mode="ios" value="roads">
        <mat-icon>fork_right</mat-icon>
        <ion-label>Drumuri și localități</ion-label>
      </ion-segment-button>
    </ion-segment>

    <ion-segment mode="ios" #operatorsSegment (ionChange)="operatorChanged()">
      <ion-segment-button value="orange">
        <img src="assets/icons/orange.svg" style="width: 45px" />
      </ion-segment-button>
      <ion-segment-button value="moldcell">
        <img src="assets/icons/moldcell.svg" style="width: 90px" />
      </ion-segment-button>
      <ion-segment-button value="unite">
        <img src="assets/icons/moldtelecom.svg" style="width: 240px" />
      </ion-segment-button>
    </ion-segment>

    <div *ngIf="showVoiceButton">
      <div *ngIf="show2G">
        <ion-fab-button
          class="technologyButton"
          matTooltip="2G GSM"
          matTooltipPosition="after"
          (click)="signalButtonClicked('2g')"
          size="small"
          [color]="getButtonColor('2g')">
          <img src="assets/icons/2g.svg" style="height: 24px; width: 24px" />
        </ion-fab-button>
      </div>
      <ion-fab-button
        class="technologyButton"
        matTooltip="3G UMTS"
        matTooltipPosition="after"
        (click)="signalButtonClicked('3g')"
        size="small"
        [color]="getButtonColor('3g')">
        <img src="assets/icons/3g.svg" style="height: 24px; width: 24px" />
      </ion-fab-button>
      <ion-fab-button
        class="technologyButton"
        matTooltip="4G LTE"
        matTooltipPosition="after"
        (click)="signalButtonClicked('4g')"
        size="small"
        [color]="getButtonColor('4g')">
        <img src="assets/icons/4g.svg" style="height: 24px; width: 24px" />
      </ion-fab-button>
    </div>
    <div *ngIf="showDataButton">
      <ion-fab-button
        matTooltip="Download Speed"
        matTooltipPosition="after"
        (click)="signalButtonClicked('download')"
        size="small"
        [color]="getButtonColor('download')">
        <ion-icon name="downloadarrow"></ion-icon>
      </ion-fab-button>
      <ion-fab-button
        matTooltip="Upload Speed"
        matTooltipPosition="after"
        (click)="signalButtonClicked('upload')"
        size="small"
        [color]="getButtonColor('upload')">
        <ion-icon name="uploadarrow"></ion-icon>
      </ion-fab-button>
    </div>
  </div>

  <ion-fab horizontal="center" slot="fixed" vertical="top">
    <ion-buttons>
      <ion-fab-button (click)="showkpiinfoPage()" size="small" color="light">
        <ion-icon name="information-circle-outline"></ion-icon>
      </ion-fab-button>
      <ion-fab-button (click)="showVideoPage()" size="small" color="light">
        <ion-icon name="play-circle-outline"></ion-icon>
      </ion-fab-button>
    </ion-buttons>
  </ion-fab>

  <div id="state-legend2" class="legend2" *ngIf="showVoiceButton">
    <ion-grid>
      <ion-row>
        <ion-col class="bgcolor">
          <strong>Aprecierea semnalului</strong>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col> <ion-icon name="radio-outline" style="color: #008000" size="small"></ion-icon><strong>&nbsp;Puternic</strong> </ion-col>
        <ion-col> <ion-icon name="radio-outline" style="color: #ff7f00" size="small"></ion-icon><strong>&nbsp;Mediu</strong> </ion-col>
        <ion-col> <ion-icon name="radio-outline" style="color: #808080" size="small"></ion-icon><strong>&nbsp;Slab</strong> </ion-col>
        <ion-col>
          <ion-icon name="radio-outline" style="color: #ff0000" size="small"></ion-icon><strong>&nbsp;Insuficient</strong>
        </ion-col>
        <ion-col>
          <ion-icon name="radio-outline" style="color: #6e050b" size="small"></ion-icon><strong>&nbsp;Lipsă&nbsp;semnal</strong>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <div id="state-legend2" class="legend2" *ngIf="showDataButton">
    <ion-grid>
      <ion-row>
        <ion-col class="bgcolor">
          <strong>Aprecierea vitezei</strong>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-icon name="ellipse" style="color: #008000" size="small"> </ion-icon>
          <strong>Foarte Bun</strong>
        </ion-col>
        <ion-col>
          <ion-icon name="ellipse" style="color: #ff7f00" size="small"> </ion-icon>
          <strong>Bun</strong>
        </ion-col>
        <ion-col>
          <ion-icon name="ellipse" style="color: #808080" size="small"> </ion-icon>
          <strong>Satisfacator</strong>
        </ion-col>
        <ion-col>
          <ion-icon name="ellipse" style="color: #ff0000" size="small"> </ion-icon>
          <strong>Nesatisfacator</strong>
        </ion-col>
        <ion-col>
          <ion-icon name="ellipse" style="color: #6e050b" size="small"> </ion-icon>
          <strong>Lipsă conexiune</strong>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
