<ion-header class="ion-no-border">
  <ion-toolbar>
    <div *ngIf='lang=="ro"' slot="start">
      <ion-title>Informație</ion-title>
    </div>
    <div *ngIf='lang=="ru"' slot="start">
      <ion-title>Информация</ion-title>
    </div>

    <ion-buttons slot="end">
      <button (click)="closeModal()" class="button icon clear">
        <mat-icon>close</mat-icon>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class="video-content">
  <ion-segment mode="ios" [(ngModel)]="lang">
    <ion-segment-button value="ro">
      <img src="assets/icons/ro.svg" style="width: 48px" />
    </ion-segment-button>
    <ion-segment-button value="ru">
      <img src="assets/icons/ru.svg" style="width: 48px" />
    </ion-segment-button>
  </ion-segment>

  <video
    id="video"
    controls="controls"
    preload="metadata"
    autoplay="autoplay"
    webkit-playsinline="webkit-playsinline"
    *ngIf="lang=='ro'"
  >
    <source
      src="./assets/video/Harta ANRCETI_ROM_ONLINE.mp4"
      type="video/mp4"
    />
  </video>

  <video
    id="video"
    controls="controls"
    preload="metadata"
    autoplay="autoplay"
    webkit-playsinline="webkit-playsinline"
    *ngIf="lang=='ru'"
  >
    <source
      src="./assets/video/Harta ANRCETI_RUS_ONLINE.mp4"
      type="video/mp4"
    />
  </video>
</div>
<ion-content> </ion-content>
