import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalitatiService {
  public items: any = [];

  constructor() {
    fetch('./assets/localitati.json')
      .then(res => res.json())
      .then(json => (this.items = json));
  }

  filterItems(searchTerm) {
    return this.items.filter(item => {
      if (searchTerm.length < 1) return false;

      if (item.properties.name_int == undefined || item.properties.name_en == undefined) {
        return (
          (item.properties.name && item.properties.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
          (item.properties['class'] && item.properties['class'].toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
        );
      }

      item.properties.name_int = item.properties.name_int.toLowerCase().split('ă').join('a');
      item.properties.name_int = item.properties.name_int.toLowerCase().split('î').join('a');
      item.properties.name_int = item.properties.name_int.toLowerCase().split('â').join('a');
      item.properties.name_int = item.properties.name_int.toLowerCase().split('ș').join('s');
      item.properties.name_int = item.properties.name_int.toLowerCase().split('ț').join('t');

      item.properties.name_en = item.properties.name_en.toLowerCase().split('ă').join('a');
      item.properties.name_en = item.properties.name_en.toLowerCase().split('î').join('i');
      item.properties.name_en = item.properties.name_en.toLowerCase().split('â').join('a');
      item.properties.name_en = item.properties.name_en.toLowerCase().split('ș').join('s');
      item.properties.name_en = item.properties.name_en.toLowerCase().split('ț').join('t');

      return (
        (item.properties.name && item.properties.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
        (item.properties.name_int && item.properties.name_int.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
        (item.properties['name:ru'] && item.properties['name:ru'].toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
        (item.properties.name_en && item.properties.name_en.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
        (item.properties['name:latin'] && item.properties['name:latin'].toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
      );
    });
  }
}
