import { Component, OnDestroy, OnInit } from '@angular/core';

import * as mapboxgl from 'mapbox-gl';

import { environment } from '../../environments/environment';
import { UtilService } from '../services/utils.service';
import { center } from 'turf';
import { Feature, FeatureCollection } from 'geojson';
import { finalize, forkJoin, tap } from 'rxjs';
import { FormControl } from '@angular/forms';
import { GeoJSONSource, MapMouseEvent } from 'mapbox-gl';
import { Observable } from 'rxjs';
import { legendChartDefault, LocalityDetails, RegionDetails } from './regions-helper';

@Component({
  selector: 'app-regions',
  templateUrl: 'regions.page.html',
  styleUrls: ['regions.page.scss']
})
export class RegionsPage implements OnDestroy {
  constructor(private utilService: UtilService) {
    this.utilService.insertVisit('region_technology').subscribe();
  }

  regionValues: RegionDetails[] = [];
  localityValues: LocalityDetails[] = [];
  regionValuesGeoJSON: FeatureCollection;
  localitiesValuesGeoJSON: FeatureCollection;
  regionsLegend = [];
  filterYears: Observable<string[]> = this.utilService.getBlAvailableYears().pipe(
    tap(years => {
      this.filterYearControl.setValue(years[0]);
      this.getValues();
    })
  );
  filterTechnologyControl: FormControl = new FormControl('FTTx,xDSL,Cablu,Altele');
  filterTypeValueControl: FormControl = new FormControl('abonati');
  filterYearControl: FormControl = new FormControl('');
  markers = {};
  markersOnScreen = {};
  private map: mapboxgl.Map;
  mapboxPopup = new mapboxgl.Popup({ closeButton: false, offset: 16 });
  hoverRegionId: string | number = null;
  isLoading: boolean = false;
  colors = ['#1E90FF', '#00FA9A', '#FF7F50', '#20B2AA'];
  donutColors = {
    FTTx: '#1E90FF',
    xDSL: '#00FA9A',
    Cablu: '#FF7F50',
    Altele: '#20B2AA'
  };
  colorScheme = {
    domain: this.colors
  };

  ionViewDidEnter(): void {
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'assets/json/style.json',
      center: [28.207089, 47.00367],
      zoom: 6.875,
      maxZoom: 19,
      minZoom: 6,
      trackResize: true,
      accessToken: environment.accessToken,
      maxBounds: new mapboxgl.LngLatBounds([23, 45], [35, 49])
    });
    this.map.addControl(new mapboxgl.ScaleControl());
    this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

    this.map.on('load', () => {
      this.map.resize();
      this.addRegionLayers();
    });

    this.map.on('mousemove', 'region-fill', e => this.onRegionInteraction(e, 'regions'));
    this.map.on('mouseleave', 'region-fill', () => this.onRegionInteractionEnd('regions'));
    this.map.on('mousemove', 'localities-fill', e => this.onRegionInteraction(e, 'localities'));
    this.map.on('mouseleave', 'localities-fill', () => this.onRegionInteractionEnd('localities'));
    this.map.on('zoomend', () => {
      this.removeMarkers();
      this.updateMarkers();
    });
  }

  getValues(): void {
    this.isLoading = true;
    forkJoin({
      regionsGeojson: this.utilService.getRegionsGeojson(),
      localitiesGeojson: this.utilService.getLocalitiesGeojson(),
      regions: this.utilService.getRegionsBL(this.filterYearControl.value),
      localities: this.utilService.getLocalitiesBL(this.filterYearControl.value)
    })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(res => {
        this.regionValues = res.regions;
        this.localityValues = res.localities;
        this.regionValuesGeoJSON = res.regionsGeojson;
        this.localitiesValuesGeoJSON = res.localitiesGeojson;
        this.setValues();
      });
  }

  setValues(): void {
    const tempChart = legendChartDefault;
    const valueType = this.filterTypeValueControl.value;
    this.regionValues.forEach(region => {
      tempChart[0].value += Number(region.valori[valueType].FTTx);
      tempChart[1].value += Number(region.valori[valueType].xDSL);
      tempChart[2].value += Number(region.valori[valueType].Cablu);
      tempChart[3].value += Number(region.valori[valueType].Altele);
      this.regionValuesGeoJSON.features.forEach((feature: Feature) => {
        if (region.regiune.toLowerCase().includes(feature.properties.name.toLowerCase())) {
          feature.properties = { ...feature.properties, ...region.valori[valueType] };
        }
      });
    });

    this.localityValues.forEach(locality => {
      tempChart[0].value += Number(locality.valori[valueType].FTTx);
      tempChart[1].value += Number(locality.valori[valueType].xDSL);
      tempChart[2].value += Number(locality.valori[valueType].Cablu);
      tempChart[3].value += Number(locality.valori[valueType].Altele);
      this.localitiesValuesGeoJSON.features.forEach((feature: Feature) => {
        if (locality.id_localitate == feature.properties?.id_localitate) {
          feature.properties = { ...feature.properties, ...locality.valori[valueType] };
        }
      });
    });

    if (valueType !== 'ponderea') {
      this.regionsLegend = tempChart;
    }
    (this.map.getSource('regions') as GeoJSONSource).setData(this.regionValuesGeoJSON);
    (this.map.getSource('localities') as GeoJSONSource).setData(this.localitiesValuesGeoJSON);
    this.updateMarkers();
  }

  private addRegionLayers(): void {
    this.map.addSource('regions', {
      type: 'geojson'
    });

    this.map.addLayer({
      id: 'region-fill',
      type: 'fill',
      source: 'regions',
      paint: {
        'fill-color': '#cecece',
        'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.75, 0.5]
      },
      maxzoom: 10
    });
    this.map.addLayer({
      id: 'region-line',
      type: 'line',
      source: 'regions',
      paint: {
        'line-color': '#000',
        'line-width': 1
      },
      maxzoom: 10
    });

    // ------

    this.map.addSource('localities', {
      type: 'geojson',
      minzoom: 10
    });

    this.map.addLayer({
      id: 'localities-fill',
      type: 'fill',
      source: 'localities',
      paint: {
        'fill-color': '#cecece',
        'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.75, 0.5]
      }
    });
    this.map.addLayer({
      id: 'localities-line',
      type: 'line',
      source: 'localities',
      paint: {
        'line-color': '#000',
        'line-width': 1
      }
    });
  }

  onRegionInteraction(e: MapMouseEvent, source: string) {
    const feature = e.features[0];
    if (feature && this.hoverRegionId != feature.id && feature.properties.name !== 'Transnistria') {
      this.mapboxPopup.addTo(this.map);
      this.mapboxPopup
        .setHTML(this.getPopupContent(feature.properties.name, feature.properties.id_localitate))
        .addClassName('bl-regions-popup');
      if (this.hoverRegionId) {
        this.map.setFeatureState({ source: 'regions', id: this.hoverRegionId }, { hover: false });
        this.map.setFeatureState({ source: 'localities', id: this.hoverRegionId }, { hover: false });
      }
      this.hoverRegionId = feature.id;
      this.map.setFeatureState({ source, id: this.hoverRegionId }, { hover: true });
    }
  }

  onRegionInteractionEnd(source: string) {
    if (this.hoverRegionId) {
      this.map.setFeatureState({ source, id: this.hoverRegionId }, { hover: false });
    }
    this.mapboxPopup.remove();
    this.hoverRegionId = null;
  }

  getPopupContent(regionName: string, idLocality: string): string {
    const units =
      this.map.getZoom() >= 10
        ? this.localityValues.filter(l => l.id_localitate == idLocality)
        : this.regionValues.filter(f => f.regiune.toLowerCase().includes(regionName.toLowerCase().replace('gagauzia', 'utag')));
    let html: string[] = units.map(
      region =>
        `<div class="bl-regions">
            <h1>${regionName}</h1>
            <ion-grid class="custom-grid">
              <ion-row>
                <ion-col>Tehnologii</ion-col>
                <ion-col>Abonatți</ion-col>
                <ion-col>Ponderea</ion-col>
              </ion-row>
              <ion-row>
                <ion-col class="technology fttx">FTTx</ion-col>
                <ion-col>${region.valori.abonati.FTTx}</ion-col>
                <ion-col>${this.getNumberComma(region.valori.ponderea.FTTx)} %</ion-col>
              </ion-row>
              <ion-row>
                <ion-col class="technology xdsl">xDSL</ion-col>
                <ion-col>${region.valori.abonati.xDSL}</ion-col>
                <ion-col>${this.getNumberComma(region.valori.ponderea.xDSL)} %</ion-col>
              </ion-row>
              <ion-row>
                <ion-col class="technology cable">Cablu</ion-col>
                <ion-col>${region.valori.abonati.Cablu}</span></ion-col>
                <ion-col>${this.getNumberComma(region.valori.ponderea.Cablu)} %</span> </ion-col>
              </ion-row>
              <ion-row>
                <ion-col class="technology other">Altele</ion-col>
                <ion-col>${region.valori.abonati.Altele}</ion-col>
                <ion-col>${this.getNumberComma(region.valori.ponderea.Altele)} %</ion-col>
              </ion-row>
              <ion-row>
                <ion-col></ion-col>
                <ion-col>Total: ${region.valori.abonati.Total}</ion-col>
                <ion-col></ion-col>
              </ion-row>
            </ion-grid>
        </div>`
    );

    if (units.length > 1) {
      this.mapboxPopup.setLngLat(this.map.getBounds().getNorthWest());
    }
    if (units.length === 1) {
      this.mapboxPopup.trackPointer();
    }
    return html.join();
  }

  private removeMarkers(): void {
    Object.keys(this.markers).forEach(m => this.markers[m].remove());
    this.markers = {};
    this.markersOnScreen = {};
  }

  changeValueType(): void {
    this.removeMarkers();
    this.updateMarkers();
  }

  changeYear(): void {
    this.removeMarkers();
    this.getValues();
  }

  changeTechnology() {
    this.removeMarkers();
    this.updateMarkers();
  }

  percentageFormat(value: number): string {
    return Number(value.toFixed(1)).toString().replace('.', ',');
  }

  valueFormat(value: number): string {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  private getNumberComma(n: number | string): string {
    const value = Number(n);
    return Number(value.toFixed(1)).toString().replace('.', ',');
  }

  updateMarkers() {
    const newMarkers = {};
    const features = this.map.getZoom() <= 10 ? this.regionValuesGeoJSON.features : this.localitiesValuesGeoJSON.features;
    for (const feature of features.filter(f => f.properties.name !== 'Transnistria')) {
      const id = feature.properties.id + feature.properties.name;
      let marker = this.markers[id];
      const featureClone = JSON.parse(JSON.stringify(feature));
      featureClone.geometry.coordinates = [featureClone.geometry.coordinates[0]];
      if (!marker) {
        const chartElement = this.createDonutChart(feature.properties, this.filterTechnologyControl.value.split(','));
        if (chartElement) {
          marker = this.markers[id] = new mapboxgl.Marker({
            element: chartElement as any
          }).setLngLat(center(featureClone).geometry.coordinates);
          newMarkers[id] = marker;
          if (!this.markersOnScreen[id]) marker.addTo(this.map);
        }
      }
    }
    for (const id in this.markersOnScreen) {
      if (!newMarkers[id]) this.markersOnScreen[id].remove();
    }
    this.markersOnScreen = newMarkers;
  }

  createDonutChart(props: { [name: string]: string }, displayProps: string[]) {
    const zoom = this.map.getZoom();
    const offsets = [];
    const values = displayProps.map(p => Number(props[p]));
    let total = 0;
    for (const count of values) {
      offsets.push(total);
      total += count;
    }
    if (total > 0) {
      const fontSize = zoom > 10 ? 12 : ((total >= 100000 ? 8 : total >= 10000 ? 10 : total >= 1000 ? 12 : 14) * zoom) / 7;
      const r = zoom * (zoom > 10 ? 2 : 3);
      const r0 = Math.round(r * 0.7);
      const w = r * 2;
      let html = `
      <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">
        ${values.map((v, i) => this.donutSegment(offsets[i] / total, (offsets[i] + v) / total, r, r0, this.donutColors[displayProps[i]]))}
        <circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
        <text dominant-baseline="central" transform="translate(${r}, ${r})">
             ${
               this.filterTypeValueControl.value === 'ponderea' && this.filterTechnologyControl.value.includes(',')
                 ? 100
                 : this.getNumberComma(total)
             }
        </text>
      </svg>`;

      const el = document.createElement('div');
      el.innerHTML = html;
      return el;
    }
    return null;
  }

  donutSegment(start: number, end: number, r: number, r0: number, color: number) {
    if (end - start === 1) end -= 0.00001;
    const a0 = 2 * Math.PI * (start - 0.25);
    const a1 = 2 * Math.PI * (end - 0.25);
    const x0 = Math.cos(a0);
    const y0 = Math.sin(a0);
    const x1 = Math.cos(a1);
    const y1 = Math.sin(a1);
    const largeArc = end - start > 0.5 ? 1 : 0;
    return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0} A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} 
    ${r + r * y1} L ${r + r0 * x1} ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0}" fill="${color}" />`;
  }

  ngOnDestroy(): void {
    this.map.remove();
  }
}
