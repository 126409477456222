<ion-app>
  <ion-menu side="start" menuId="first" contentId="content1">
    <div>
      <ion-title>
        <img src="assets/icons/anrceti.svg" style="max-width: 60%" />
      </ion-title>
    </div>
    <ion-content>
      <ion-list class="menu-items">
        <ion-menu-toggle class="menu-item" *ngFor="let item of menuItems" (click)="menuItemClicked(item)">
          <ion-icon [name]="item.icon" size="medium"></ion-icon>
          {{ item.title }}
        </ion-menu-toggle>
        <ion-menu-toggle class="menu-item" (click)="showVideoModal()">
          <ion-icon name="videocam-outline"></ion-icon>
          Spot publicitar
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="content1"></ion-router-outlet>
</ion-app>
