import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';
import { SearchPageModule } from '../modal/search/search.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { RegionsPage } from './regions.page';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MaterialModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    SearchPageModule,
    NgxChartsModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      {
        path: '',
        component: RegionsPage
      }
    ])
  ],
  declarations: [RegionsPage],
  exports: [RegionsPage]
})
export class RegionsPageModule {}
